.drawer {
    h1 {
        margin-top: 7px;
        font-size : 34px;
    }

    h5 {
        margin    : 0 auto;
        text-align: center;
    }
}

.socialContainer {
    margin-top: 18px;

    div>div>div {
        margin: 0 auto;
        cursor: pointer;
    }
}