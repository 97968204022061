/*
 * Colors
 * ========================================================================== */

$white-base:            hsl(255, 255, 255);
$gray-darker:           #222;
$gray-dark:             #404040;
$gray:                  #555;
$gray-light:            #777;
$gray-lighter:          #eee;

/*
 * Typography
 * ========================================================================== */

$font-family-base:      'Segoe UI', 'HelveticaNeue-Light', sans-serif;

/*
 * Layout
 * ========================================================================== */

$max-content-width:     1000px;

/*
 * Media queries breakpoints
 * ========================================================================== */

$screen-xs-min:         480px;  /* Extra small screen / phone */
$screen-sm-min:         768px;  /* Small screen / tablet */
$screen-md-min:         992px;  /* Medium screen / desktop */
$screen-lg-min:         1200px; /* Large screen / wide desktop */

/*
 * Animations
 * ========================================================================== */

$animation-swift-out:   .45s cubic-bezier(0.3, 1, 0.4, 1) 0s;
